import * as React from 'react';
import Citysetting1 from '../../components/citysetting/citysetting1';
import Citysetting2 from '../../components/citysetting/citysetting2';
import Citysetting3 from '../../components/citysetting/citysetting3';

import {
  AboutWrapper,
} from './style';



const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

 
const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <AboutWrapper>
    <div>
      <div>
        <h1>分析する市町村を選択</h1>
        <br></br>
        <div>
          <h2>都市１</h2> 
          <Citysetting1 />
        </div>
        <div>
          <h2>都市２</h2> 
          <Citysetting2 />
        </div>
        <div>
          <h2>都市３</h2> 
          <Citysetting3 />
        </div>
    </div>
    </div>
    </AboutWrapper>

  );
};
 
export default App;



